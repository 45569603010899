import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Item from './components/item';
import CategoryBox from './components/CategoryBox';
import Header from './components/Header';

interface MenuItem {
  name: string
  price: number
  isStartedPrice: boolean
  description: string
  calories: number
  category: string
  image: string
}

interface Category {
  name: string
  description: string
  items: MenuItem[]
}

interface RestaurantData {
  version: string
  info: {
    name: string
    logo: string
    contact: {
      phone: {
        number: string
        message: string
      }
      whatsapp: {
        number: string
        message: string
      }
      email: string
      website: string
    }
  }
  address: Array<{
    street: string
    city: string
    state: string
    zip: string
  }>
  items: MenuItem[]
  categories: Category[]
}

const Cardapio: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredProducts, setFilteredProducts] = useState<Category[]>([]);

  // const data: RestaurantData = require('./data.json');

  const data: RestaurantData = require('./data.json');

  const products: Category[] = data.categories;
  useEffect(() => {
    setFilteredProducts(products);
  }, [products]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = products.map(product => {
      const filteredItems = product.items.filter(item => {
        return item.name.toLowerCase().includes(query) ||
                item.description.toLowerCase().includes(query);
      });
      return { ...product, items: filteredItems };
    }).filter(product => product.items.length > 0);
    setFilteredProducts(filtered);
  };

  return (
    <div>
      <Header imagePath={data.info.logo} restaurantName={data.info.name} />
      <Container maxWidth="lg">
        <TextField
            label="Buscar"
            variant="outlined"
            fullWidth
            margin="normal"
            value={searchQuery}
            onChange={handleSearch}
        />
        <CategoryBox categories={filteredProducts.map(product => product.name)} />
        <h2>Cardápio</h2>
        {filteredProducts.map((product, index) => (
          <div key={index}>
            <h3 id={product.name}>{product.name}</h3>
            <p>{product.description}</p>
              {product.items.map((item, itemIndex) => (
                <Item
                    key={itemIndex}
                    productName={item.name}
                    productDescription={item.description}
                    isStartedPrice={item.isStartedPrice ?? false}
                    productValue={item.price}
                    imagePath={item.image}
                />
              ))}
          </div>
        ))}
      </Container>
    </div>
  );
};

export default Cardapio;
