import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TabContext from '@mui/lab/TabContext';

const CategoryBox = ({ categories }) => {
  const [value, setValue] = React.useState('');

  const handleChange = (event, newValue) => {
    if (typeof newValue === 'string') {
      setValue(newValue);
    }
  };

  return (
    <TabContext value={value}>
      <Box sx={{ width: '100%', bgcolor: 'background.paper', padding: '8px' }}>
        {/* Tabs and Tab Panels */}
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {categories.map((category, index) => (
            <Tab
              key={index}
              label={category}
              value={index.toString()}
              component="a"
              href={`#${category}`}
            />
          ))}
        </Tabs>
      </Box>
    </TabContext>
  );
};

CategoryBox.propTypes = {
  categories: PropTypes.array.isRequired
};

export default CategoryBox;
