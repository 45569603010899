import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Contact from './contact';

function Copyright () {
  return (
    <Typography variant="body2" color="text.secondary">
      {'Copyright © Criado por '}
      <Link color="inherit" href="https://restaurantedigital.com/">
        RestauranteDigital
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const Footer = ({ restaurantName }: { restaurantName: any }) => {
  return (
        <Box
          component="footer"
          sx={{
            py: 3,
            px: 2,
            mt: 'auto',
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[200]
                : theme.palette.grey[800]
          }}
        >
          <Container sx= {{ mb: 4 }} maxWidth="md">
            <Contact />
          </Container>
          <Container maxWidth="sm">
            <Typography variant="body1">
              {restaurantName} - Cardápio Digital
            </Typography>
            <Copyright />
          </Container>
        </Box>
  );
};

Footer.propTypes = {
  restaurantName: PropTypes.string.isRequired
};

export default Footer;
