import * as React from 'react';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Cardapio from './Cardapio';
import HomePage from './Home';
import NotFound from './NotFound';

const App: React.FC = () => (
  <BrowserRouter>
    <Routes>
    <Route path="/" element={<HomePage />} />
    <Route path="/cardapio" element={<Cardapio />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
  </BrowserRouter>
);

export default App;
