import React from 'react';
import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';

function Item ({ productName, productDescription, isStartedPrice, productValue, imagePath }) {
  return (
    <Card variant="outlined" sx={{ display: 'flex', alignItems: 'center', pt: 0, mt: 1 }}>
  <Box sx={{ flex: 1, p: 1 }}>
    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 1 }}>
      <Typography variant="h7" component="div">
        {productName}
      </Typography>
    </Stack>
    <Typography color="text.secondary" variant="caption" align="justify" style={{ whiteSpace: 'pre-line' }}>
      {productDescription}
    </Typography>
    <Typography variant="subtitle2" component="div" sx={{ color: '#900', mt: 2 }}>
      {isStartedPrice && 'A partir de '}
      R${productValue.toFixed(2)}
    </Typography>
  </Box>
  <Box sx={{ width: '120', textAlign: 'right' }}>
    <CardMedia
      component="img"
      width="50%"
      height="150"
      src={imagePath}
      alt={productName}
      sx={{
        borderRadius: '8px',
        objectFit: 'cover',
        objectPosition: 'center',
        width: 90,
        height: 90,
        marginLeft: 'auto'
      }}
    />
  </Box>
</Card>
  );
}

Item.propTypes = {
  productName: PropTypes.string.isRequired,
  productDescription: PropTypes.string.isRequired,
  isStartedPrice: PropTypes.boolean,
  productValue: PropTypes.number.isRequired,
  imagePath: PropTypes.string.isRequired
};

export default Item;
