import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/system';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

const Header = ({ imagePath, restaurantName }) => {
  return (
    <div className="header" style={{ backgroundColor: '#f3f3f3', padding: '20px' }}>
    <Container maxWidth="lg">
      <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
        <Box sx={{ flex: '0 0 auto', mr: 2 }}>
          <Card variant="outlined" sx={{ width: 120, height: 120 }}>
            <CardMedia
              component="img"
              width="100%"
              height="100%"
              src={imagePath}
              alt="Restaurant Image"
            />
          </Card>
        </Box>
        <Typography variant="h4" component="h1">
          {restaurantName}
        </Typography>
      </Box>
      </Container>
    </div>
  );
};

Header.propTypes = {
  imagePath: PropTypes.string.isRequired,
  restaurantName: PropTypes.string.isRequired
};

export default Header;
