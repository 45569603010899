import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f5f5f5',
    padding: '16px',
    borderRadius: '2px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    maxWidth: '600px',
    margin: 'auto',
    marginTop: '10px'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    maxWidth: '400px',
    margin: 'auto',
    padding: '16px'
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

const Contact = () => {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    // show a alert message
    console.log('data: ', { name, email, message });
    alert('TODO: implement form');

    // TODO: make a POST request to the server
  };

  return (
    <Container className={classes.root}>
      <Typography variant="h4" align="center" gutterBottom>
        Entre em Contato
      </Typography>
      <form onSubmit={handleSubmit} className={classes.form} noValidate autoComplete="off">
        <TextField id="name" value={name} onChange={(e) => { setName(e.target.value); }} label="Seu Nome" variant="outlined" />
        <TextField id="email" value={email} onChange={(e) => { setEmail(e.target.value); }} label="Seu Email" variant="outlined" />
        <TextField
          id="message"
          value={message}
          onChange={(e) => { setMessage(e.target.value); }}
          label="Sua Mensagem"
          multiline
          rows={4}
          variant="outlined"
        />
        <div className={classes.buttonWrapper}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
          >
            Enviar Mensagem
          </Button>
        </div>
      </form>
    </Container>
  );
};

export default Contact;
