import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from './components/footer';
import { Box } from '@mui/system';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Divider } from '@mui/material';
import { Instagram, Facebook, Map, RestaurantMenu, WhatsApp } from '@mui/icons-material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';

const theme = createTheme({
  palette: {
    primary: {
      main: '#e53935'
    },
    secondary: {
      main: '#f44336'
    }
  }
});

const HomePage: React.FC = () => {
  const navigate = useNavigate();

  const goToCardapio = () => {
    navigate('/cardapio');
  };

  interface MenuItem {
    name: string
    price: number
    description: string
    calories: number
    category: string
    image: string
  }

  interface Category {
    name: string
    description: string
    items: MenuItem[]
  }

  interface RestaurantData {
    version: string
    info: {
      name: string
      logo: string
      description: string
      contact: {
        phone: {
          number: string
          message: string
        }
        whatsapp: {
          phone: string
          message: string
        }
        instagram: string
        facebook: string
        email: string
        website: string
        ifood: string
      }
      addresses: Array<{
        street: string
        city: string
        state: string
        zip: string
        googleMaps: string
      }>
    }
    items: MenuItem[]
    categories: Category[]
  }
  // Get data from data.json
  const data: RestaurantData = require('./data.json');

  const handleClickWhatsapp = () => {
    const phoneNumber: string = data.info.contact.whatsapp.phone;
    const message: string = data.info.contact.whatsapp.message;
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };

  useEffect(() => {
    document.title = data.info.name;
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh'
        }}
      >
        <Container maxWidth="md">
          <Box sx={{ textAlign: 'center', marginTop: 4 }}>
            <Typography variant="h3" mb={2}>
              {data.info.name}
            </Typography>
            <Card variant="outlined" sx={{ maxWidth: 240, margin: 'auto' }}>
              <CardMedia
                component="img"
                width="240"
                height="240"
                src={data.info.logo}
                alt={data.info.name}
              />
            </Card>
            <Typography variant="caption" mt={2}>
              {data.info.description}
            </Typography>
          </Box>

          <Box sx={{ textAlign: 'center', marginTop: 4 }}>
            <Button variant="contained" onClick={goToCardapio} startIcon={<RestaurantMenu />}>
              Cardápio
            </Button>
          </Box>

          <Divider sx={{ my: 4 }} />

          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h5">Faça o seu Pedido Online</Typography>
            <Button variant="contained" startIcon={<WhatsApp />} onClick={handleClickWhatsapp}>
              Whatsapp
            </Button>
            {data.info.contact.ifood && data.info.contact.ifood.length > 0 &&
            <Button variant="contained" startIcon={<WhatsApp />} href={data.info.contact.ifood} sx={{ marginLeft: 2 }}>
              iFood
            </Button>
            }
          </Box>

          <Divider sx={{ my: 4 }} />

          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h5">Redes Sociais</Typography>
            <Button variant="contained" href={data.info.contact.instagram} target="_blank" startIcon={<Instagram />}>
              Instagram
            </Button>
            <Button variant="contained" href={data.info.contact.facebook} target="_blank" sx={{ marginLeft: 2 }} startIcon={<Facebook />}>
              Facebook
            </Button>
          </Box>

          <Divider sx={{ my: 4 }} />

          <Box>
            <Typography variant="h5" sx={{ textAlign: 'center' }}>Nossas Unidades</Typography>
            {data.info.addresses.map((address: any) => (
              <Box key={address.name} sx={{ textAlign: 'center', my: 2 }}>
                <CardMedia
                  component="img"
                  src={address.image}
                  alt={address.name}
                  sx={{ borderRadius: '8px', width: '150px', height: '150px', margin: 'auto' }}
                />
                <Typography variant="h6">{address.name}</Typography>
                <Typography>{address.street}</Typography>
                <Typography>{address.city}</Typography>
                <Typography sx={{ color: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Link href={address.googleMaps} rel="noreferrer" target="_blank" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Map /> Google Maps
                  </Link>
                </Typography>
              </Box>
            ))}
          </Box>
        </Container>
        <Footer restaurantName={data.info.name} />
      </Box>
    </ThemeProvider>
  );
};

export default HomePage;
